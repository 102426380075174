
/* Thin 100 */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
/* Thin 100 Italic */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
/* ExtraLight 200 */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
/* ExtraLight 200 Italic */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
/* Light 300 */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
/* Light 300 Italic */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
/* Regular 400 */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/* Regular 400 Italic */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
/* Medium 500 */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/* Medium 500 Italic */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
/* SemiBold 600 */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* SemiBold 600 Italic */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
/* Bold 700 */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/* Bold 700 Italic  */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
/* ExtraBold 800 */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
/* ExtraBold 800 Italic  */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
/* Black 900  */ 
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
/* Black 900 Italic  */
@font-face {
  font-family: 'Raleway';
  src: url('../../assets/fonts/Raleway/Raleway-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
